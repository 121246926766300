import Cookies from 'js-cookie';
export function checkUsernameInAnnouncementCookie(cookieName: string, username:string):boolean {
    let cookies = Cookies.get(cookieName);
    if (!cookies) {
        return false;
    }
    cookies = JSON.parse(cookies);
    const found:any = Object.keys(cookies).find((item) => {
        return item === username && cookies[item] === true;
    })
    return typeof found !== 'undefined' ;

}
export function setUsernameInAnnouncementCookie(cookieName: string, username:string):void {
    let cookies = Cookies.get(cookieName) || {};
    if(typeof cookies === 'string') {
        cookies = JSON.parse(cookies);
    }
    cookies[username] = true;
    Cookies.set(cookieName, cookies, { expires: 365*10 });
    
}