import createElement from "@src/utils/createElement";
import styles from './webappWinningPopup.module.sass';
import godRayGlow1 from './god-ray-glow-1.png';
import godRay1 from './god-ray-1.png';
import godRay2 from './god-ray-2.png';
import godRay3 from './god-ray-3.png';

const createCoin = ():HTMLDivElement => {
    return createElement(/*html*/`
        <div class='${styles.idle}'>
            <div class='${styles.coinContainer}'>
                <div class='${styles.coinRedGlowContainer}'>
                    <div class='${styles.coinRedGlow}'></div>
                </div>
                <div class='${styles.coinBuildUpParticles}'>
                    <div class='${styles.coinBuildUpTranslateY}'>
                        ${Array.from(Array(Number(styles.buildUpParticlesCount))).map(() => {
                            return /*html*/`<div class='${styles.particle}'></div>`;
                        }).join('')}
                    </div>
                </div>
                <div class='${styles.coinGodRays}'>
                    <div class='${styles.coinGodRaysEntrance}'>
                        <div class='${styles.coinGodRaysRotation}'>
                            <img src='${godRay1}' class='${styles.coinGodRay}'/>
                            <img src='${godRay2}' class='${styles.coinGodRay}'/>
                            <img src='${godRay3}' class='${styles.coinGodRay}'/>
                            <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                            <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                            <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                        </div>
                    </div>
                </div>
                <div class='${styles.coin}'>
                    <div class='${styles.coinFace}'>
                        <div class='${styles.coinDesign}'></div>
                    </div>
                </div>
            </div>
        </div>
    `) as HTMLDivElement;
}

export default createCoin;