export enum SOURCE {
    MP = 1,
    HL = 2
}

export enum CLIENT_TYPE {
    WEBSITE = 1,
    WEBAPP = 2
}

export interface iFeatureAnnouncementPopup {
    showPopUp: (source: SOURCE, clientType: CLIENT_TYPE, zIndex?:string) => void
}

export interface iWinningPopup {
    showWinningPopup: (amount: number, source: SOURCE, clientType: CLIENT_TYPE, zIndex?:string, depositLink?: string, callbacks?: {
        onShuffleEnd?: () => void
        onAnimationEnd?: () => void
    }) => Promise<void>
}