import createElement from "@src/utils/createElement";
import { CLIENT_TYPE, iWinningPopup, SOURCE } from "../interfaces";
import styles from './webappWinningPopup.module.sass';
import strings from '@src/strings';
import addCommas from '@src/utils/addCommaSeparatorToNumber';
import createCoin from "./createCoin";
import godRayGlow1 from './god-ray-glow-1.png';
import godRay1 from './god-ray-1.png';
import godRay2 from './god-ray-2.png';
import godRay3 from './god-ray-3.png';
import createIllustration from "./createIllustration";
import liteVersion from "@src/utils/liteVersion";

const counterDigitsContainerId = 'counterDigitsContainerId';
const counterExplosionsContainerId = 'counterExplosionsContainerId';
const counterWaterfallId = 'counterWaterfallId';
const winningPopupContinueCtaId = 'winningPopupContinueCtaId';
const winningPopupCoinId = 'winningPopupCoinId';
const ctaClickAreaId = 'ctaClickAreaId';

export default class WebAppWinningPopup implements iWinningPopup {
    explosionTimeoutReference: number;
    autoFadeTimeoutReference: number;
    animationEndCloseTimeoutReference: number;
    closeRemoveClassTimeoutReference: number;
    $counterDigitsContainer: HTMLDivElement;
    $counterExplosionsContainer: HTMLDivElement;
    $counterWaterfall: HTMLDivElement;
    $continueCta: HTMLDivElement;
    $coinContainer: HTMLDivElement;
    $ctaClickArea: HTMLDivElement;
    idleClass = styles.idle;
    coinFadeTargetId: string;
    manuallyDismissed: boolean;

    $element: HTMLDivElement;
    _uid: number;

    isLite = liteVersion();

    constructor (uid: string) {
        // this.isLite = true;

        this.$element = createElement(/*html*/`
            <div class='${[
                styles.container,
                this.isLite && styles.liteVersion,
                this.isLite && styles.idle,
            ].join(' ')}'>
                <div class='${styles.background}'>
                </div>
                <div class='${styles.ctaClickArea}' id="${ctaClickAreaId}${uid}">
                </div>
                <div class='${styles.coinContainer}' id="${winningPopupCoinId}${uid}">
                    <div class='${styles.coinRedGlowContainer}'>
                        <div class='${styles.coinRedGlow}'></div>
                    </div>
                    <div class='${styles.coinBuildUpParticles}'>
                        <div class='${styles.coinBuildUpTranslateY}'>
                            ${Array.from(Array(Number(styles.buildUpParticlesCount))).map(() => {
                                return /*html*/`<div class='${styles.particle}'></div>`;
                            }).join('')}
                        </div>
                    </div>
                    <div class='${styles.coinGodRays}'>
                        <div class='${styles.coinGodRaysEntrance}'>
                            <div class='${styles.coinGodRaysRotation}'>
                                <img src='${godRay1}' class='${styles.coinGodRay}'/>
                                <img src='${godRay2}' class='${styles.coinGodRay}'/>
                                <img src='${godRay3}' class='${styles.coinGodRay}'/>
                                <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                                <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                                <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                            </div>
                        </div>
                    </div>
                    <div class='${styles.coinRevealSwooshMovement}'>
                        <div class='${styles.coinRevealScale}'>
                            <div class='${styles.coinEndBounce}'>
                                <div class='${styles.coinRumble}'>
                                    <div class='${styles.coinExplosionBuildUpScale}'>
                                        <div class='${styles.coinExplode}'>
                                            <div class='${styles.coin}'>
                                                <div class='${styles.coinFace}'>
                                                    <div class='${styles.coinDesign}'></div>
                                                    ${this.isLite ? '' : /*html*/`<div class='${styles.blazingHotGlows}'>
                                                        <div class='${styles.blazingHotGlow}'></div>
                                                        <div class='${styles.blazingHotGlow}'></div>
                                                        <div class='${styles.blazingHotGlow}'></div>
                                                    </div>`}
                                                </div>
                                                ${this.isLite ? '' : /*html*/`
                                                    <div class='${styles.coinBody}'>
                                                        ${Array.from(Array(Number(styles.coinLayerCount) - 2)).map(() => {
                                                            return /*html*/`
                                                            <div></div>`;
                                                        }).join('')}
                                                    </div>
                                                    <div class='${styles.coinMid}'></div>
                                                    <div class='${styles.coinMid}'></div>
                                                    <div class='${styles.coinFace}'>
                                                        <div class='${styles.coinDesign}'></div>
                                                    </div>
                                                `}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ${this.isLite ? '' : /*html*/`
                        <div>
                            ${Array.from(Array(4)).map(() => {
                                return /*html*/`                    
                                    <div class='${styles.explosionParticles}'>
                                        <div class='${styles.explosionOpacity}'>
                                            <div class='${styles.particles}'>
                                                <div class='${styles.texture}'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`;
                            }).join('')}
                        </div>
                        <div class='${styles.shockWave}'>
                            <div class='${styles.shockWaveOuter}'>
                                <div></div>
                            </div>
                        </div>`
                    }
                </div>
                <div class='${styles.heading}'>
                    <div class='${styles.headingExplode}'>
                        <div class='${styles.headingRumble}'>
                            ${strings.winningHeading}
                        </div>
                    </div>
                </div>
                <div class='${styles.counter}'>
                    <div class='${styles.counterExplosions}' id='${counterExplosionsContainerId}${uid}'></div>
                    <div class='${styles.waterfall}' id='${counterWaterfallId}${uid}'>
                        <div class='${styles.waterfallRedGlow}'>
                        </div>
                        ${this.isLite ? '' : /*html*/`
                            <div class='${styles.waterfallLinearMask}'>
                                <div class='${styles.waterfallFunnelMask}'>
                                    <div class='${styles.waterfallParticles}'>
                                        <div class='${styles.waterfallParticlesTexture}'>
                                        </div>
                                    </div>
                                    <div class='${styles.waterfallParticles}'>
                                        <div class='${styles.waterfallParticlesTexture}'>
                                        </div>
                                    </div>
                                    <div class='${styles.waterfallParticles}'>
                                        <div class='${styles.waterfallParticlesTexture}'>
                                        </div>
                                    </div>
                                    <div class='${styles.waterfallParticles}'>
                                        <div class='${styles.waterfallParticlesTexture}'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `}
                    </div>
                    <div class='${styles.counterScale}'>
                        <div class='${styles.counterRumble}'>
                            <div class='${styles.counterExplosion}'>
                                <div class='${styles.counterMask}' id='${counterDigitsContainerId}${uid}'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='${styles.cta}' id='${winningPopupContinueCtaId}${uid}'>
                    <div class='${styles.ctaReveal}'>
                        <div class='${styles.ctaContent}'>
                            ${strings.ctaLabel}
                        </div>
                    </div>
                </div>
            </div>
        `) as HTMLDivElement;
        document.body.appendChild(this.$element);
        
        this.$counterDigitsContainer = document.getElementById(counterDigitsContainerId+uid) as HTMLDivElement;
        this.$counterExplosionsContainer = document.getElementById(counterExplosionsContainerId+uid) as HTMLDivElement;
        this.$counterWaterfall = document.getElementById(counterWaterfallId+uid) as HTMLDivElement;
        this.$continueCta = document.getElementById(winningPopupContinueCtaId+uid) as HTMLDivElement;
        this.$coinContainer = document.getElementById(winningPopupCoinId+uid) as HTMLDivElement;
        this.$ctaClickArea = document.getElementById(ctaClickAreaId+uid) as HTMLDivElement;

        // this.$continueCta.addEventListener('click', this.onContinueClick);
        this.$continueCta.addEventListener('mousedown', this.onCTAPressed);
        this.$continueCta.addEventListener('touchstart', this.onCTAPressed);
        this.$continueCta.addEventListener('touchend', this.onCTARelease);
        this.$continueCta.addEventListener('mouseup', this.onCTARelease);
        this.$ctaClickArea.addEventListener('mousedown', this.onCTAPressed);
        this.$ctaClickArea.addEventListener('mouseup', this.onCTARelease);
        this.$ctaClickArea.addEventListener('touchstart', this.onCTAPressed);
        this.$ctaClickArea.addEventListener('touchend', this.onCTARelease);
    }

    onCTAPressed = ():void => {
        if (!this.$element.classList.contains(styles.hideAllExceptCoin) && !this.$element.classList.contains(styles.pressed)) {
            this.$element.classList.add(styles.pressed);
        }
    }

    onCTARelease = ():void => {
        if (!this.$element.classList.contains(styles.hideAllExceptCoin) && !this.$element.classList.contains(styles.released)) {
            this.manuallyDismissed = true
            this.$element.classList.add(styles.released);
            this.$element.classList.add(styles.manuallyDismissed);
            this.moveCoinToTarget();
        }
    }

    showWinningPopup = async (
        amount: number, 
        source: SOURCE, 
        clientType: CLIENT_TYPE, 
        zIndex?:string,
        depositLink?: string,
        callbacks?: {
            onAnimationEnd: () => void
        },
    ):Promise<void> => {
        console.log(amount, source, clientType, zIndex, depositLink);
        this.setCounterAmount(amount);
        this.$element.style.zIndex = zIndex;
        this.reset();
        window.requestAnimationFrame(() => {
            this.$element.classList.add(styles.show);
            window.clearTimeout(this.explosionTimeoutReference);
            window.clearTimeout(this.autoFadeTimeoutReference);
            window.clearTimeout(this.animationEndCloseTimeoutReference);
            window.clearTimeout(this.closeRemoveClassTimeoutReference);
            this.explosionTimeoutReference = window.setTimeout(() => {
                this.$element.classList.add(styles.explode);
            }, Number(styles.timeTillExplosion.replace('s','')) * 1000 * 0.95/* 0.95 is the point when counter animation reaches over scroll end */);

            this.autoFadeTimeoutReference = window.setTimeout(() => {
                if (callbacks && typeof callbacks.onAnimationEnd === 'function') {
                    callbacks.onAnimationEnd();
                } else {
                    this.moveCoinToTarget();
                }
            }, Number(styles.entireAnimationDuration.replace('s','')) * 1000);
        });
    }

    setCounterAmount = (amount: number):void => {
        const displayString = `¥${addCommas(amount)}`;
        
        const digits = Array.from(displayString);

        while (this.$counterDigitsContainer.firstChild) {
            this.$counterDigitsContainer.removeChild(this.$counterDigitsContainer.lastChild);
        }

        while (this.$counterExplosionsContainer.firstChild) {
            this.$counterExplosionsContainer.removeChild(this.$counterExplosionsContainer.lastChild);
        }

        const numberOfNumbers = displayString.replace(/[^0-9]/g,'').length;

        let iterationCount = numberOfNumbers;

        let nthDigit = 0;

        let rollingTimeEachDigit: number;

        digits.map((char: string) => {
            const sequence = [];

            const intChar = parseInt(char);

            const _NaN = isNaN(intChar);

            let startingNumber = intChar;

            if (!_NaN) {

                for (let i = 0; i < 10 + iterationCount * 4; i++) {
                    if (startingNumber > 9) {
                        startingNumber -= 10;
                    }

                    sequence.push(startingNumber);

                    startingNumber += 1 + Math.round((Math.random() * 2));
                }

                if (sequence[sequence.length - 1] === intChar) {
                    sequence[sequence.length - 1] += 1;
                }

                sequence.push(intChar);

                if (nthDigit === 0) {
                    rollingTimeEachDigit = Number(styles.counterBuildUpDuration.replace('s','')) / (sequence.length - 1);
                }

                iterationCount--;
                nthDigit++;
            }

            console.log('sequence', sequence, iterationCount);

            const rollingTimeForThisDigit = rollingTimeEachDigit * (sequence.length - 1);

            const $element = createElement(/*html*/`
                <div class='${[
                    styles.counterRoller,
                    char === ',' ? styles.charComma : '',
                    char === '¥' ? styles.charYen : '',
                    char !== ',' && char !== '' ? (styles as unknown as {[key: string]: string})['char'+char] : '',
                    _NaN ? styles.NaN : ''
                ].join(' ')}'
                    style='animation-duration: ${rollingTimeForThisDigit}s'
                >${_NaN ? /*html*/`<div class='${styles.digit} ${char === ',' ? styles.charComma : ''}${char === '¥' ? styles.charYen : ''}'></div>` : (
                    this.isLite ? 
                        /*html*/`<div class='${styles.digit} ${(styles as unknown as {[key: string]: string})['char'+sequence[0]]}'>
                        </div>` :
                        sequence.map((digit: number) => {
                            return /*html*/`<div class='${styles.digit} ${(styles as unknown as {[key: string]: string})['char'+digit]}'>
                            </div>`;
                        }).join('')
                    )}</div>
            `) as HTMLElement;

            if (!this.isLite) {
                const $explosionParticles = createElement(/*html*/`
                    <div class='${styles.digit} ${char === ',' ? styles.charComma : ''}${char === '¥' ? styles.charYen : ''}${(styles as unknown as {[key: string]: string})['char'+char]} ${styles.explosionParticles}'>
                        <div class='${styles.explosionOpacity}'>
                            <div class='${styles.particles}'>
                                <div class='${styles.texture}'>
                                </div>
                            </div>
                        </div>
                    </div>
                `) as HTMLElement;

                this.$counterExplosionsContainer.appendChild($explosionParticles);
            }
            
            this.$counterDigitsContainer.appendChild($element);
        });

        window.requestAnimationFrame(() => {
            const displayWidth = this.$counterDigitsContainer.clientWidth * Number(styles.counterDisplayScale) + 30;

            this.$counterWaterfall.style.width = `${displayWidth}px`;
            this.$counterWaterfall.style.marginLeft = `-${displayWidth/2}px`;
        });
    }

    close = ():void => {
        this.$element.classList.add(styles.hide);

        this.closeRemoveClassTimeoutReference = window.setTimeout(() => {
            this.reset();
        }, Number(styles.fadeAllOutDuration.replace('s','')) * 1000);

        if(typeof this.onClose === 'function') {
            this.onClose();
        }
    }

    reset = ():void => {
        this.$element.classList.remove(styles.show);
        this.$element.classList.remove(styles.explode);
        this.$element.classList.remove(styles.hideAllExceptCoin);
        this.$element.classList.remove(styles.hide);
        this.$element.classList.remove(styles.pressed);
        this.$element.classList.remove(styles.manuallyDismissed);
        this.$element.classList.remove(styles.released);
        window.clearTimeout(this.explosionTimeoutReference);
        window.clearTimeout(this.autoFadeTimeoutReference);
        window.clearTimeout(this.animationEndCloseTimeoutReference);

        this.$coinContainer.style.transform = '';
        this.$coinContainer.style.opacity = '';
        this.manuallyDismissed = false;
    }

    onClose: () => void;

    moveCoinToTarget = ():void => {
        if (!this.isLite) {
            const $target = document.getElementById(this.coinFadeTargetId);
    
            if ($target !== null) {
                const targetRect = $target.getBoundingClientRect();
                const coinRect = this.$coinContainer.getBoundingClientRect();
                const offsetX = targetRect.x + targetRect.width/2 - coinRect.width/2 - coinRect.x;
                const offsetY = targetRect.y + targetRect.height/2 - coinRect.height/2 - coinRect.y;
    
                this.$coinContainer.style.transform = `translate(${offsetX}px, ${offsetY}px) scale(0.1)`;
                this.$coinContainer.style.opacity = '0';
            } else {
                this.$coinContainer.style.transform = `scale(0.1)`;
                this.$coinContainer.style.opacity = '0';
            }
    
            this.animationEndCloseTimeoutReference = window.setTimeout(
                this.close,Number(this.manuallyDismissed  ? styles.manuallyEndingCoinMoveDuration.replace('s','') : styles.endingCoinMoveDuration.replace('s','')) * 1000
            );
    
            this.$element.classList.add(styles.hideAllExceptCoin);
        } else {
            this.close();
        }
    }

    makeCoin = createCoin;

    makeIllustration = createIllustration;
}   