import {iWinningPopup, iFeatureAnnouncementPopup, SOURCE, CLIENT_TYPE} from '@src/components/interfaces';
// import Cookies, { set } from 'js-cookie';
import { checkUsernameInAnnouncementCookie, setUsernameInAnnouncementCookie } from '@src/utils/cookie';
import { logEvent, Events } from '@src/utils/userTracking';
import axios from 'axios';

const INCREASE_PAYOUT_ANNOUNCEMENT_MESSAGE = 'Increase Payout 60s USDJPY Announcement';

export default class SDK {
    CLIENT_TYPE = CLIENT_TYPE;
    SOURCE = SOURCE;
    winningPopup: iWinningPopup;
    featureAnnouncementPopup: iFeatureAnnouncementPopup;
    baseApi: string;
    busy: boolean;

    constructor (winningPopup: iWinningPopup, featureAnnouncementPopup?: iFeatureAnnouncementPopup) {
        this.winningPopup = winningPopup;
        this.featureAnnouncementPopup = featureAnnouncementPopup;
        this.busy = false;
        // we need to remove '/pwa' from the configured api to get the base path
        // we discussed creating a new whitelabel config for this, but we thought its better to change the value of the API and apply the changes to the web app
        // TODO: assess best way to configure base API
        this.baseApi = process.env.API_URL?process.env.API_URL.replace(/\/pwa/ig, ''): '';
    }

    showAnimationPopup = (amount: number, source: 1 | 2, clientType: 1 | 2, zIndex?: string, depositLink ?: string, callbacks?: {
        onShuffleEnd: () => void
    }):Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            try {
                this.winningPopup.showWinningPopup(amount, source, clientType, zIndex, depositLink, callbacks);
                logEvent(Events.JACKPOT_WIN, {
                    amount,
                    source,
                    clientType,
                    zIndex
                });
                resolve();
            } catch(e) {
                reject(e);
            }

        })
    }
    
    showAnnouncementPopup = (username: string, createAt: number, source: 1 | 2, clientType: 1 | 2, zIndex?: string): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            this.checkAnnoucementMessage(INCREASE_PAYOUT_ANNOUNCEMENT_MESSAGE, username)
                .then((data) => {
                    if (data) {
                        this.setAnnoucementMessage(INCREASE_PAYOUT_ANNOUNCEMENT_MESSAGE, username);
                        if(clientType === CLIENT_TYPE.WEBSITE && this.featureAnnouncementPopup !== undefined) {
                            this.featureAnnouncementPopup.showPopUp(source, clientType, zIndex);
                        }
                        logEvent(Events.INCREASE_PAYOUT_ANNOUNCEMENT_195, {
                            source,
                            clientType,
                            zIndex
                        });
                    } 
                    resolve(data);
                }).catch(() => {
                    reject();
                })
        
        })
    }
    checkAnnoucementMessage(message: string, username: string):Promise<boolean>  {
        
        if (checkUsernameInAnnouncementCookie(INCREASE_PAYOUT_ANNOUNCEMENT_MESSAGE, username)) {
            return Promise.resolve(false);
        }
        return new Promise((resolve, reject) => {
            this.busy = true;
            axios.get(`${this.baseApi}/show-announcement`, { params: {
                username,
                message_name: message
            }}).then((res) => {
                if (res.status === 200 ) {
                    resolve(res.data.show_announcement)
                } else if (res.status === 400) { 
                    reject(res.data)
                } else if (res.status === 500) {
                    reject(res.data)
                } else {
                    resolve(false);
                }
                this.busy = false;
            }).catch((err) => {
                console.log(err)
                reject(err);
                this.busy = false;
                // testing purposes
                // resolve(true);
            });
        } )
    }
    setAnnoucementMessage(message: string, username: string):Promise<boolean>  {
        setUsernameInAnnouncementCookie(message, username);
        return new Promise((resolve, reject) => {
            axios.post(`${this.baseApi}/update-announcement-flag`, {
                username,
                message_name: message
            }).then((res) => {
                if (res.status === 200 ) {
                    resolve(res.data.status === 'success')
                } else if (res.status === 400) { 
                    reject(res.data)
                } else if (res.status === 500) {
                    reject(res.data)
                } else {
                    resolve(false);
                }
            }).catch((err) => {
                console.log(err)
                reject(err);
            });
        } )
    }

    version(): string {
        return process.env.BUILD_VERSION
    }
    
}