const maskImage = ():boolean => {
    return CSS.supports('(mask-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 12%, rgba(0,0,0,1) 88%, rgba(0,0,0,0) 100%)) or (-webkit-mask-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 12%, rgba(0,0,0,1) 88%, rgba(0,0,0,0) 100%))');
};

const filterBlur = ():boolean => {
    return CSS.supports('filter: blur(6px) drop-shadow(0px 0px 10px #FFE664)');
};

export default {
    maskImage,
    filterBlur
};