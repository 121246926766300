import addCommas from "@src/utils/addCommaSeparatorToNumber";
import createElement from "@src/utils/createElement";
import styles from "./webappWinningPopup.module.sass";
import godRayGlow1 from './god-ray-glow-1.png';
import godRay1 from './god-ray-1.png';
import godRay2 from './god-ray-2.png';
import godRay3 from './god-ray-3.png';
import strings from '@src/strings';

const createIllustration = (amount = 100000, classes?: {
    containerClass?: string,
    coinContainerClass?: string,
    headingClass?: string,
    counterClass?: string
}):HTMLDivElement => {
    const displayString = `¥${addCommas(amount)}`;
    
    const digits = Array.from(displayString);

    return createElement(/*html*/`
        <div class='${[
            styles.illustration,
            styles.idle,
            classes && classes.containerClass
        ].join(' ')}'>
            <div class='${[
                styles.coinContainer,
                classes && classes.coinContainerClass
            ].join(' ')}'>
                <div class='${styles.coinRedGlowContainer}'>
                    <div class='${styles.coinRedGlow}'></div>
                </div>
                <div class='${styles.coinBuildUpParticles}'>
                    <div class='${styles.coinBuildUpTranslateY}'>
                        ${Array.from(Array(Number(styles.buildUpParticlesCount))).map(() => {
                            return /*html*/`<div class='${styles.particle}'></div>`;
                        }).join('')}
                    </div>
                </div>
                <div class='${styles.coinGodRays}'>
                    <div class='${styles.coinGodRaysEntrance}'>
                        <div class='${styles.coinGodRaysRotation}'>
                            <img src='${godRay1}' class='${styles.coinGodRay}'/>
                            <img src='${godRay2}' class='${styles.coinGodRay}'/>
                            <img src='${godRay3}' class='${styles.coinGodRay}'/>
                            <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                            <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                            <img src='${godRayGlow1}' class='${styles.coinGodRayGlow}'/>
                        </div>
                    </div>
                </div>
                <div class='${styles.coin}'>
                    <div class='${styles.coinFace}'>
                        <div class='${styles.coinDesign}'></div>
                    </div>
                </div>
            </div>
            <div class='${[
                styles.heading,
                classes && classes.headingClass
            ].join(' ')}'>
                <div class='${styles.headingExplode}'>
                    <div class='${styles.headingRumble}'>
                        ${strings.winningHeading}
                    </div>
                </div>
            </div>
            <div class='${styles.counterIdleGlow}'>
                <div class='${[
                    styles.counter,
                    styles.counterScale,
                    classes && classes.counterClass
                ].join(' ')}'>
                    <div class='${styles.counterMask}'>
                        ${digits.map((digit) => {
                            return /*html*/`<div class='${styles.digit} ${digit === ',' ? styles.charComma : ''}${digit === '¥' ? styles.charYen : ''} ${(styles as unknown as {[key: string]: string})['char'+digit]}'>
                                ${Array.from(Array(Number(styles.counterDigitIdleParticleCount))).map(() => {
                                    return /*html*/`<div class='${styles.particle}'></div>`;
                                }).join('')}
                            </div>`;
                        }).join('')}
                    </div>
                </div>
            </div>
        </div>
    `) as HTMLDivElement;
}

export default createIllustration;