import styles from './components/sdk/sdk.module.sass';
export default {
    ctaLabel: '取引へ進む',
    annoucementContent: '幸運なトレーダーは取引を行った後、<br/>リアルタイムでキャッシュバックを獲得する<br/>チャンスがあります。取引すればするほど、<br/>ジャックポット獲得金額と頻度は<br/>高くなっていきます。',
    announcementCtaLabel: '了解しました', 
    announcementCtaMoreInfoLabel: '詳細へ', 
    winningHeading: 'ジャックポット!',
    depositWin: 'キャッシュバックを獲得しました',
    depositWinText: '入金を行い、キャッシュバックを獲得',
    ctaDeposit: '入金を行う',
};

export const btcString = {
    announcementCtaLabel: 'BITCOINオプション取引',
    intro: '新商品のご紹介',    
    announcementHeader1: 'Turbo取引に',
    announcementHeader2: 'BITCOINが登場！',
    announcementContent: `<span class="${styles.word}">BITCOIN取引の</span><span class="${styles.word}">人気に</span><span class="${styles.word}">伴い、</span><span class="${styles.word}">Turbo取引の</span><span class="${styles.word}">30秒から5分に</span><span class="${styles.word}">BITCOINオプションを</span><span class="${styles.word}">追加いたします。</span><span class="${styles.word}">このオプションは</span><span class="${styles.word}">期間限定で</span><span class="${styles.word}">ご利用</span><span class="${styles.word}">いただけます</span><span class="${styles.word}">ので、</span><span class="${styles.word}">この機会を</span><span class="${styles.word}">お見逃しなく！</span>`
}
export const ethString = {
    announcementCtaLabel: 'ETHEREUMで取引',
    intro: 'ついに登場',    
    announcementHeader1: 'Turbo取引に',
    announcementHeader2: 'BITCOINが登場！',
    announcementContent: `<span class="${styles.word}">本日から</span><span class="${styles.word}">ETHEREUM</span><span class="${styles.word}">オプションが、</span><span class="${styles.word}">Turbo取引に</span><span class="${styles.word}">登場いたしました。</span>`
}

export const jackpot500String = {
    announcementCtaLabel: '今すぐ取引する',
    intro: 'いよいよ開始です',    
    // announcementHeader1: 'Turbo取引に',
    // announcementHeader2: 'BITCOINが登場！',
    announcementContent: `<span class="${styles.word}">ジャックポットで</span><span class="${styles.word}">当たる</span><span class="${styles.word}">最高額が</span><span class="${styles.word}">¥500,000に</span><span class="${styles.word}">増加しました。</span><span class="${styles.word}">今すぐ</span><span class="${styles.word}">このチャンスを</span><span class="${styles.word}">掴みましょう！</span>`
}

export const btcjpyString = {
    announcementCtaLabel: '今すぐ取引する',
    announcementContent: `<span class="${styles.word}">多くの方からの</span><span class="${styles.word}">ご要望が</span><span class="${styles.word}">ついに</span><span class="${styles.word}">実現しました！</span><span class="${styles.word}">本日より</span><span class="${styles.word}">BTC/JPYと</span><span class="${styles.word}">ETH/JPYが</span><span class="${styles.word}">取引可能です。</span>`
}

export const increasePayoutString = {
    announcementCtaLabel: 'USD/JPYを取引する',
    announcementContent: `<span class="${styles.word}">USD/JPYの</span><span class="${styles.word}">1分Turbo</span><span class="${styles.word}">オプションの</span><span class="${styles.word}">ペイアウト率が</span><span class="${styles.word}">195%に</span><span class="${styles.word}">上がります。</span>`
}