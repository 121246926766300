const addCommas = (yourNumber: number, hideZeroPrecision = false):string => {
    if (yourNumber === undefined || yourNumber === null || isNaN(yourNumber)) {
        return '0';
    }
    //Seperates the components of the number
    const n = String(yourNumber).split('.');
    //Comma-fies the first part
    n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    /**
     * to remove zero precision value
     */
    const precision = Number(n[1]);
    if (hideZeroPrecision && !precision) {
        return String(n[0]);
    }
    //Combines the two sections
    return n.join('.');
};

export default addCommas;