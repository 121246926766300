const holderElement = document.createElement('div');

const createElement: (domString: string, dontTrim?: boolean) => NodeListOf<Node>|Node = (domString, dontTrim = false) => {
    while (holderElement.firstChild) {
        holderElement.removeChild(holderElement.lastChild);
    }

    holderElement.innerHTML = dontTrim ? domString : domString.trim();

    return holderElement.childNodes.length == 1 ? holderElement.childNodes[0] : holderElement.childNodes;
}

export default createElement;