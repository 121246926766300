
export function logEvent(name: string, params?:{[key:string]:any}): void 
{
    fullStoryEvent(name, params);
    newRelicEvent(name, params);
}

function fullStoryEvent(name: string, params?: any): void
{
    const FS = window['FS'];
    if (FS && FS.event) {
        if (params) {
            FS.event(name, params);
            console.log('fullStory event', name, params);
        } else {
            FS.event(name);
            console.log('fullStory event', name);
        }
    } else {
        if (params) {
            console.log('fullStory not available', name, params);
        } else {
            console.log('fullStory not available', name);
        }
    }
}

function newRelicEvent(name: string, params?: any): void
{
    const NR = window['newrelic'];
    if (NR && NR.addPageAction) {
        if (params) {
            NR.addPageAction(name, params);
            console.log('newrelic event', name, params);
        } else {
            NR.addPageAction(name);
            console.log('newrelic event', name);
        }
    } else {
        if (params) {
            console.log('newrelic not available', name, params);
        } else {
            console.log('newrelic not available', name);
        }
    }
}


export const Events = {
    JACKPOT_ANNOUNCEMENT: 'JACKPOT_ANNOUNCEMENT',
    JACKPOT_ANNOUNCEMENT_CTA_CONFIRM: 'JACKPOT_ANNOUNCEMENT_CTA_CONFIRM',
    JACKPOT_ANNOUNCEMENT_CTA_LEARN_MORE: 'JACKPOT_ANNOUNCEMENT_CTA_LEARN_MORE',
    JACKPOT_WIN: 'JACKPOT_WIN',
    JACKPOT_DISMISS_WIN_POPUP: 'JACKPOT_DISMISS_WIN_POPUP',
    JACKPOT_DISMISS_ANNOUNCEMENT: 'JACKPOT_DISMISS_ANNOUNCEMENT',
    BTC_ANNOUNCEMENT: 'BTC_ANNOUNCEMENT',  
    BTC_ANNOUNCEMENT_CTA_CONFIRM: 'BTC_ANNOUNCEMENT_CTA_CONFIRM',  
    BTC_ANNOUNCEMENT_CTA_DISMISS: 'BTC_ANNOUNCEMENT_CTA_DISMISS',  
    BTC_DISMISS_ANNOUNCEMENT: 'BTC_DISMISS_ANNOUNCEMENT',
    ETH_ANNOUNCEMENT: 'ETH_ANNOUNCEMENT',  
    ETH_ANNOUNCEMENT_CTA_CONFIRM: 'ETH_ANNOUNCEMENT_CTA_CONFIRM',  
    ETH_ANNOUNCEMENT_CTA_DISMISS: 'ETH_ANNOUNCEMENT_CTA_DISMISS',  
    ETH_DISMISS_ANNOUNCEMENT: 'ETH_DISMISS_ANNOUNCEMENT',
    JACKPOT_500K_ANNOUNCEMENT: 'JACKPOT_500K_ANNOUNCEMENT',  
    JACKPOT_500K_ANNOUNCEMENT_CTA_DISMISS: 'JACKPOT_500K_ANNOUNCEMENT_CTA_DISMISS',  
    JACKPOT_500K_ANNOUNCEMENT_CTA_CONFIRM: 'JACKPOT_500K_ANNOUNCEMENT_CTA_CONFIRM',  
    BTC_JPY_ANNOUNCEMENT: 'BTC_JPY_ANNOUNCEMENT',  
    BTC_JPY_ANNOUNCEMENT_CTA_DISMISS: 'BTC_JPY_ANNOUNCEMENT_CTA_DISMISS',  
    BTC_JPY_ANNOUNCEMENT_CTA_CONFIRM: 'BTC_JPY_ANNOUNCEMENT_CTA_CONFIRM',  
    INCREASE_PAYOUT_ANNOUNCEMENT: 'INCREASE_PAYOUT_ANNOUNCEMENT',  
    INCREASE_PAYOUT_ANNOUNCEMENT_CTA_DISMISS: 'INCREASE_PAYOUT_ANNOUNCEMENT_CTA_DISMISS',  
    INCREASE_PAYOUT_ANNOUNCEMENT_CTA_CONFIRM: 'INCREASE_PAYOUT_ANNOUNCEMENT_CTA_CONFIRM',  
    INCREASE_PAYOUT_ANNOUNCEMENT_195: 'INCREASE_PAYOUT_ANNOUNCEMENT_195',  
    INCREASE_PAYOUT_ANNOUNCEMENT_CTA_DISMISS_195: 'INCREASE_PAYOUT_ANNOUNCEMENT_CTA_DISMISS_195',  
    INCREASE_PAYOUT_ANNOUNCEMENT_CTA_CONFIRM_195: 'INCREASE_PAYOUT_ANNOUNCEMENT_CTA_CONFIRM_195',  

};